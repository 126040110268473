import {
  AudiovisualDistributor,
  AudiovisualDistributor2x,
} from "@thumbnails/Modules"
import {
  AudiovisualDistributorLayout1,
  AudiovisualDistributorLayout2,
} from "@layouts"
import {
  CardCollectionLayout1,
  CardCollectionLayout2,
  CardCollectionLayout3,
} from "@layouts"

export default {
  schemaType: "module",
  displayName: "Audiovisual Distributor",
  component: "AudiovisualDistributor",
  category: "collections",
  dataPacks: ["AUDIOVISUAL"],
  sectionList: {
    AcademicVideo: ["relatedContent"],
    ArticlesList: ["relatedContent"],
    BasicTemplate: ["mainContent"],
    EpicAwardDetail: ["mainContent", "relatedContent"],
    EpicAwardList: ["relatedContent"],
    EventDetail: ["mainContent", "relatedContent"],
    EventList: ["relatedContent"],
    GeneralInfoDetail: ["gidMainContent", "gidRelatedContent"],
    GeneralInfoList: ["relatedContent"],
    Landing: ["mainContent"],
    NewsDetail: ["relatedContent"],
    NewsList: ["relatedContent"],
    ProgramDetail: ["relatedContent"],
    ProgramsList: ["relatedContent"],
    ProjectDetail: ["mainContent", "relatedContent"],
    ProjectList: ["relatedContent"],
    PublicationDetail: ["relatedContent"],
    PublicationsList: ["relatedContent"],
    ScholarshipsList: ["relatedContent"],
    SearchResults: ["mainContent", "relatedContent"],
    SitemapTemplate: ["relatedContent"],
    StoriesList: ["relatedContent"],
    StoryDetail: ["mainContent", "relatedContent"],
    TeacherDetail: ["mainContent", "relatedContent"],
    TeachersList: ["relatedContent"],
  },
  configTabs: [
    {
      title: "content",
      fields: [
        {
          title: "Title",
          type: "HeadingField",
          key: "title",
          advanced: true,
          hideable: true,
          default: { tag: "h2", content: "Title" },
          options: [
            { value: "h1", label: "H1" },
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "span" },
          ],
          humanReadable: true,
        },
        {
          title: "Subtitle",
          type: "RichText",
          key: "subtitle",
          html: true,
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Detail",
          type: "RichText",
          key: "detail",
          html: true,
          hideable: true,
          humanReadable: true,
        },
        {
          title: "Tag Distributor",
          type: "ComponentArray",
          maxItems: 1,
          key: "tagDistributor",
          whiteList: ["TagDistributor"],
        },
        {
          title: "",
          type: "ReferenceField",
          key: "data",
          sources: [
            { structuredData: "VIDEOS" },
            { structuredData: "PODCASTS" },
          ],
          helptext: "This module will show maximum 20 elements",
        },
        {
          title: "Content hierarchy for SEO",
          type: "Select",
          key: "cardTitleTag",
          options: [
            { value: "h2", label: "H2" },
            { value: "h3", label: "H3" },
            { value: "h4", label: "H4" },
            { value: "span", label: "Span" },
          ],
        },
        {
          title: "No Results Text",
          type: "TextField",
          key: "noResultsText",
          humanReadable: true,
        },
        {
          title: "Show description at available audiovisual card",
          type: "RadioGroup",
          key: "showDescription",
          options: [
            {
              value: true,
              title: "Yes",
              name: "yes",
            },
            {
              value: false,
              title: "No",
              name: "no",
            },
          ],
        },
        {
          title: "Show date of audiovisual content",
          type: "RadioGroup",
          key: "showDate",
          options: [
            {
              value: true,
              title: "Yes",
              name: "yes",
            },
            {
              value: false,
              title: "No",
              name: "no",
            },
          ],
        },
        {
          title: "Podcast link text",
          type: "TextField",
          key: "podcastLinkText",
          humanReadable: true,
          helptext: "This only affects to design two.",
        },
        {
          title: "Video link text",
          type: "TextField",
          key: "videoLinkText",
          humanReadable: true,
          helptext: "This only affects to design two.",
        },
        {
          title: "Grouping Link",
          type: "ComponentContainer",
          whiteList: ["Link"],
          key: "groupingLink",
          hideable: true,
        },
      ],
    },
    {
      title: "config",
      fields: [
        {
          title: "Anchor ID",
          type: "TextField",
          key: "anchorID",
          prefix: "#",
          hideable: true,
          helptext:
            "Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
        },
        {
          title: "View",
          type: "VisualUniqueSelection",
          key: "view",
          options: [
            {
              value: "viewOne",
              img: AudiovisualDistributorLayout1,
            },
            {
              value: "viewTwo",
              img: AudiovisualDistributorLayout2,
            },
          ],
          columns: 3,
        },
        {
          title: "Layout",
          key: "layout",
          type: "VisualUniqueSelection",
          options: [
            {
              value: "L001",
              img: CardCollectionLayout1,
            },
            {
              value: "L002",
              img: CardCollectionLayout2,
            },
            {
              value: "L003",
              img: CardCollectionLayout3,
            },
          ],
          columns: 2,
        },
        {
          title: "Vertical Spacing",
          key: "verticalSpacing",
          type: "RadioGroup",
          options: [
            { name: "small", value: "small", title: "Small" },
            { name: "medium", value: "medium", title: "Medium" },
            { name: "large", value: "large", title: "Large" },
          ],
        },
      ],
    },
  ],

  default: {
    component: "AudiovisualDistributor",
    anchorID: null,
    theme: "default",
    verticalSpacing: "medium",
    view: "viewOne",
    layout: "L002",
    title: {
      content: "Video Center",
      tag: "h2",
    },
    subtitle: "Discover our videos",
    detail: null,
    tagDistributor: [],
    cardTitleTag: "h3",
    data: {
      mode: "auto",
      order: "alpha",
      sources: [
        {
          structuredData: "VIDEOS",
          filterOperator: "OR",
          globalOperator: "AND",
        },
        {
          structuredData: "PODCASTS",
          filterOperator: "OR",
          globalOperator: "AND",
        },
      ],
      quantity: 4,
      preferenceLanguage: false,
    },
    noResultsText: "Sorry, no results were found.",
    showDescription: false,
    showDate: true,
    podcastLinkText: null,
    videoLinkText: null,
    groupingLink: {
      component: "Link",
      text: "View all",
      style: "secondary",
      url: {
        href: null,
        linkToURL: null,
        linkTo: null,
        newTab: false,
        noFollow: false,
      },
    },
  },
  thumbnails: {
    "1x": AudiovisualDistributor,
    "2x": AudiovisualDistributor2x,
  },
}
