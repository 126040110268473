export default {
  es: {
    icons: {
      ariaLabel: {
        backString: "Atrás",
        bot: "Bot",
        closeAccordion: "Colapsar la información",
        closeMenu: "Cerrar menú",
        closeModal: "Cerrar modal",
        closeSelector: "Cerrar selector",
        closeSubmenu: "Cerrar submenú",
        closeTranscription: "Cerrar transcripción",
        copied: "Copiado",
        copy: "Copiar",
        deleteFilter: "Borrar filtro",
        followUs: "Síguenos",
        goToHome: "Va a página de inicio",
        goToLinkedinProfile: "Ir al perfil de Linkedin",
        goToNextBlogs: "Pasar a siguientes blogs",
        goToNextPage: "Ir a la siguiente página",
        goToNextPrograms: "Ir a siguientes programas",
        goToNextSlide: "Pasar a la siguiente diapositiva",
        goToNextYear: "Ir al siguiente año",
        goToNextTabs: "Ver siguientes pestañas",
        goToPreviousBlogs: "Pasar a anteriores blogs",
        goToPreviousPage: "Ir a la página anterior",
        goToPreviousPrograms: "Ir a anteriores programas",
        goToPreviousSlide: "Pasar a la anterior diapositiva",
        goToPreviousYear: "Ir al anterior año",
        goToPreviousTabs: "Ver anteriores pestañas",
        lastMonth: "Mes anterior",
        listView: "Vista de lista",
        mapView: "Vista de mapa",
        nextLevel: "Siguiente nivel",
        nextMonth: "Siguiente mes",
        notShowAll: "No mostrar todo",
        notShowCalendar: "No ver calendario",
        open: "Abrir",
        openAccordion: "Desplegar más información",
        openMenu: "Abrir menú",
        openNewTab: "Se abrirá en una nueva pestaña",
        openProgram: "Abrir programa",
        openScholarship: "Abrir beca",
        openSelector: "Abrir selector",
        playVideo: "Reproducir video",
        pauseVideo: "Pausar video",
        represent: "Icono que representa a",
        search: "Buscar",
        share: "Compartir",
        showAll: "Mostrar todo",
        showCalendar: "Ver calendario",
        showLessVideos: "Mostrar menos videos",
        showMoreVideos: "Mostrar más videos",
        watch: "Ver",
        removeProgramToCompare: "Eliminar programa para comparar",
      },
      arrows: {
        aDownload: "Descargar",
        dropDown: "Abrir",
        dropUp: "Cerrar",
        externalLink: "Link Externo",
        higher: "Flecha derecha",
        less: "Flecha izquierda",
      },
      components: {
        attach: "Adjuntar",
        question: "Interrogación",
        quote: "Comillas",
      },
      initials: {
        backArrowSlim: "Descargar",
        backArrow: "Izquierda",
        closeMenu: "Cerrar menú",
        downArrow: "Más información abajo",
        email: "Email",
        openMenu: "Abrir menú",
        openMenuFit: "Abrir menú",
        play: "Reproducir",
        pause: "Pausar",
      },
      socialMedia: {
        facebook: "Facebook",
        flickr: "Flickr",
        instagram: "Instagram",
        linkedin: "Linkedin",
        podcast: "Podcast",
        snapchat: "Snapchat",
        tikTok: "TikTok",
        whatsApp: "WhatsApp",
        x: "X",
        youtube: "Youtube",
      },
      general: {
        add: "Más contenido",
        advancedSearch: "Búsqueda avanzada",
        award: "Premio",
        calendar: "Calendario",
        case: "Case",
        close: "Cerrar",
        comment: "Conversación",
        device: "Dispositivo",
        document: "Documento",
        info: "Información",
        list: "Lista",
        email: "Buzón de correos",
        mapPoint: "Punto en el mapa",
        microphone: "Micrófono",
        notAdd: "Menos contenido",
        phone: "Teléfono",
        programFavorite: "Programa favorito",
        check: "check",
        remove: "Ver menos",
        search: "Búsqueda",
        shape: "Forma",
        shield: "Medalla",
        student: "Estudiante",
        time: "Reloj",
        user: "Usuario",
        view: "Ver",
      },
      logos: {
        alumni: "Logo Alumni",
        architecture: "Logo School of Architecture & Design",
        architectureFifAnn:
          "Logo School of Architecture & Design 50 Aniversario",
        business: "Logo Business School",
        businessFifAnn: "Logo Business School 50 Aniversario",
        ccltm: "Logo Center for Corporate Learning and Talent Management",
        cgc: "Logo Center for the Governance of Change",
        gpc: "Logo Global Policy Center",
        corporateRelat: "Logo Corporate Relations",
        entrepreneurship: "Logo Entrepreneurship",
        financialAID: "Logo Financial AID",
        foundation: "Logo Foundation",
        humanities: "Logo School of Humanities",
        humanitiesFifAnn: "Logo School of Humanities 50 Aniversario",
        ie: "Logo IE",
        ieNYC: "Logo IE NYC",
        law: "Logo Law School",
        lawFifAnn: "Logo Law School 50 Aniversario",
        leadership: "Logo Center for Leadership & Innovation in me",
        lifelong: "Logo Lifelong Learning",
        lifelongFifAnn: "Logo Lifelong Learning 50 Aniversario",
        spega: "Logo School of Politics, Economics & Global Affairs",
        spegaFifAnn:
          "Logo School of Politics, Economics & Global Affairs 50 Aniversario",
        sogp: "Logo School of Global & Public affairs",
        sogpFifAnn: "Logo School of Global & Public affairs 50 Aniversario",
        sst: "Logo School of Science & Technology",
        sstFifAnn: "Logo School of Science & Technology 50 Aniversario",
        talentCareer: "Logo Talent & Careers",
        uni: "Logo University",
        uniFifAnn: "Logo University 50 Aniversario",
      },
    },
    initial: {
      goToMain: "Ir al contenido principal de la página",
    },
    ui: {
      followUs: "Síguenos",
      breadcrumb: { home: "Inicio" },
      loadMore: "Cargar más",
      showing: "mostrando",
      of: "de",
    },
    components: {
      addToCalendar: {
        add: "Añadir al calendario",
      },
      audiovisualCard: {
        aviable: "Disponible desde ",
        listenPodcast: "Escuchar podcast",
        watchVideo: "Ver vídeo",
      },
      blogCard: { blogDetail: "Detalle del blog" },
      eventCard: {
        info: "Más información",
        register: "Registrarse",
      },
      featuredProgramCard: {
        discover: "Descubre el programa",
        apply: "Solicita admisión",
        filterOptions: "Filtra opciones",
        quickFacts: {
          location: "ubicación",
          format: "formato",
          duration: "duración",
          language: "idioma",
          intake: "convocatoria",
        },
      },
      insightsCard: { insights: "Detalle del insight" },
      newsCard: { newsDetail: "Detalle de la noticia" },
      personCard: { profile: ",su perfil" },
      programCategory: {
        programDetail: "Detalle del programa",
      },
      projectCard: {
        authorProfessor: "Autor (profesor)",
        authorStudent: "Autor (estudiante)",
        subject: "Asignatura",
        date: "Fecha",
        program: "Programa",
        tutor: "Tutor",
        projectLink: "Enlace del projecto",
      },
      storyCard: {
        storyFrom: ", su historia",
        hoverLink: "Ver la historia",
      },
    },
    modules: {
      serverError: "Error del servidor",
      articlesDistributor: {
        readArticle: "Leer artículo completo",
        discoverArticles: "Descubre más artículos",
      },
      blogDistributor: {
        discoverBlogs: "Descubre más blogs",
      },
      countdown: {
        days: "días",
        hours: "horas",
        minutes: "minutos",
      },
      feedbackForm: {
        name: { label: "Nombre*", error: "Introduce un nombre" },
        surname: { label: "Apellidos*", error: "Introduce los apellidos" },
        email: { label: "Email*", error: "Introduce un email válido" },
        message: { label: "Mensaje*", error: "Introduce un mensaje" },
        legalConditions: {
          prefix: "Acepto las",
          linkText: "Condiciones Legales",
          suffix: "",
          error: "Debes aceptar las condiciones legales",
        },
        send: {
          label: "Enviar",
          success: {
            prefix: "Gracias",
            suffix: "La información se ha enviado correctamente",
          },
        },
      },
      generalInfo: {
        about: "Acerca de",
        more: "Ver más",
        filter: "Información General filtrada",
      },
      newsDistributor: {
        discoverNews: "Descubre más noticias",
      },
      programDistributor: {
        apply: "Solicita admisión",
        discover: "Descubre el programa",
        discoverPrograms: "Descubre más programas",
      },
      programQuickFacts: {
        duration: "Duración",
        language: "Idioma",
        location: "Localización",
        intake: "Convocatoria",
      },
      projectDistributor: {
        discoverProject: "Descuble el proyecto",
        discoverProjects: "Descubre más proyectos",
      },
      publicationDistributor: {
        readPublication: "Leer publicación completa",
        discoverPublications: "Descubre más publicaciones",
      },
      specializationDistributor: {
        apply: "Solicita admisión",
        viewDetail: "Ver detalle",
        specialization: "Especialización",
      },
    },
    templates: {
      results: "Resultados",
      epicAwardList: {
        loading: "Cargando...",
      },
      epicAwardDetail: {
        winner: "Ganador/a de los EPIC Awards",
        nominated: "Nominado/a de los EPIC Awards",
      },
      eventList: {
        hybrid: "Eventos Híbridos",
        faceToFace: "Eventos Presenciales",
        online: "Eventos en Línea",
      },
      publicationDetail: {
        date: "Fecha",
        author: "Autor(es)",
        organization: "Organización",
        ISBN: "ISBN",
        publication: "Publicación",
        publicationType: "Tipo de publicación",
        academicArea: "Area académica",
        citation: "Referencia",
        share: "Compartir",
      },
      projectDetail: {
        program: "Programa",
        authorTeacher: "Autor (profesor)",
        authorStudent: "Autor (estudiante)",
        tutor: "Tutor",
        projectLink: "Link del proyecto",
        subject: "Asignatura",
        date: "Fecha",
      },
      programDetail: {
        programTabDetail: "Detalles de programa",
        downloadBrochure: "Descargar folleto",
        apply: "Aplicar",
      },
      programComparator: {
        addToComparator: "Añadir al comparador",
        selectAtLeast: "Selecciona al menos dos programas para comparar",
        comparePrograms: "Compara los programas",
        otherProgram: "Otro programa",
        addedToComparator: "Añadido al comparador",
        changeThePrograms: "Cambiar los programas a comparar",
        seeTheDifferentFields: "Ver solo los campos diferentes",
        abstract: "Resumen",
        forPeopleWho: "Para personas que",
        toBecome: "Para llegar a ser",
        reasonsWhy: "Motivos por los que estudiarlo",
        beyondTheProgram: "Más allá del programa",
        residentialPeriods: "Periodos residenciales",
        findOutMore: "Descubre más",
        apply: "Solicita admisión",
        discoverProgram: "Descubre el programa",
        fix: "Fijar",
        fixed: "Fijado",
        remove: "Eliminar",
        noProgramSelected: "No hay programa seleccionado",
        addAnotherProgram: "Añadir otro programa",
      },
      programList: {
        discover: "Descubre el programa",
      },
      projectList: {
        authorProfessor: "Profesor",
        authorStudent: "Estudiante",
      },
      storyDetail: {
        programStudied: "Programa estudiado",
        aboutMe: "Sobre mí",
      },
      specializationDetail: {
        specialization: "Especialización",
        back: "Volver",
      },
      teacherDetail: {
        name: "Nombre",
        lastName: "Apellidos",
        position: "Posición",
        contact: "Contacto",
        cv: "CV",
        linkedin: "Linkedin",
        personalWeb: "Web personal",
        nationality: "Nacionalidad",
        orcid: "Orcid",
        scopus: "Scopus",
        externalLink: "Link externo",
      },
    },
    introForm: {
      hubspot: "Formulario Hubspot",
    },
    forms: {
      firstName: { label: "Nombre*", error: "Introduce el nombre" },
      lastName: { label: "Apellidos*", error: "Introduce los apellidos" },
      email: { label: "Email*", error: "Introduce un email válido" },
      phone: {
        label: "Teléfono*",
        error: "Introduce un número de teléfono válido",
      },
      program: { label: "Programa*", error: "Introduce un programa" },
      subject: { label: "Asignatura*", error: "Introduce una asignatura" },
      intake: { label: "Convocatoria*", error: "Introduce una convocatoria" },
      web: { label: "Web", error: "Introduce una web" },
      company: {
        label: "Nombre de la empresa*",
        error: "Introduce nombre de la empresa",
      },
      city: { label: "Ciudad*", error: "Introduce una ciudad" },
      country: { label: "País*", error: "Introduce un país" },
      image: {
        label: "Adjunte su logo",
        error: "Introduce el logo de menos de 2MB",
      },
      description: {
        label: "Breve descripción (Máx. 200 caracteres)*",
        error: "Introduce una descripción de máximo 200 caracteres",
        errorLength: "Máx. 200 caracteres",
      },
      allSectors: "Todos los sectores",
      send: {
        label: "Enviar",
        success: {
          prefix: "Gracias",
          suffix: "La información se ha enviado correctamente",
        },
      },
      noFileSelected: "Ningún archivo seleccionado",
      success: { label: "Gracias" },
      legalConditions: {
        prefix:
          "Consiento que Fundación IE trate mis datos personales para gestionar mi aportación, conforme a lo estipulado en la",
        linkText: "Condiciones Legales",
        suffix: "",
        error: "Debes aceptar las condiciones legales",
      },
      dataConditions: {
        prefix:
          "Consiento que, como donante, mis datos sean publicados en la web de la Fundación",
      },
      informationConditions: {
        prefix:
          "Fundación IE lleva a cabo actividades que pueden ser de tu interés. Por favor, marca esta casilla si deseas que te informemos de las mismas.",
      },
    },
    filters: {
      searchbar: "¿Qué estás buscando?",
      select: "Selecciona",
      all: "Seleccionar todo",
      filters: "Filtros",
      improveSearch: "Búsqueda avanzada",
      clearFilters: "Limpiar filtros",
      clearAdvancedFilters: "Limpiar filtros avanzados",
      applyFilters: "Aplicar filtros",
      ACADEMIC_AREA: {
        label: "Selecciona una área académica",
        header: "Todas las áreas académicas",
      },
      AWARD_CATEGORY: {
        label: "Selecciona una categoría de premios",
        header: "Todos las categorías",
      },
      CENTERS: {
        label: "Selecciona un centro",
        header: "Todos los centros",
      },
      CLASSIFICATION_TYPE: {
        label: "Selecciona un tipo de clasificación",
        header: "Todos las clasificaciones",
      },
      DURATION: {
        label: "Selecciona una duración",
        header: "Todas las duraciones",
      },
      EVENT_TYPE: {
        label: "Selecciona un tipo de evento",
        header: "Todos los tipos de eventos",
      },
      FACULTY_CATEGORY: {
        label: "Selecciona una categoría del profesorado",
        header: "Todas las categorías del profesorado",
      },
      FORMAT: {
        label: "Selecciona un formato",
        header: "Todos los formatos",
      },
      GENDER: {
        label: "Selecciona un género",
        header: "Todos los géneros",
      },
      INTAKE: {
        label: "Selecciona una convocatoria",
        header: "Todas las convocatorias",
      },
      LANGUAGE: {
        label: "Selecciona un idioma",
        header: "Todos los idiomas",
      },
      LOCATION: {
        label: "Selecciona una localización",
        header: "Todas las localizaciones",
      },
      MODE_STUDY: {
        label: "Selecciona un modo de estudio",
        header: "Todos los modos de estudio",
      },
      PATHWAYS: {
        label: "Selecciona tu pathway",
        header: "Todos los pathways",
      },
      PATHWAYS_EXECUTIVE: {
        label: "Selecciona tu pathway",
        header: "Todos los pathways",
      },
      PODCAST_TYPE: {
        label: "Selecciona un tipo de podcast",
        header: "Todos los tipos de podcasts",
      },
      PROFILES: {
        label: "Selecciona un perfil",
        header: "Todos los perfiles",
      },
      PROGRAM_TYPE: {
        label: "Selecciona un tipo de programa",
        header: "Todos los tipos de programas",
      },
      PROGRAMS: {
        label: "Selecciona un programa",
        header: "Todos los programas",
      },
      PUBLICATION_TYPE: {
        label: "Selecciona un tipo de publicación",
        header: "Todos los tipos de publicación",
      },
      REGION: {
        label: "Selecciona una región",
        header: "Todas las regiones",
      },
      SCHOLARSHIP_TYPE: {
        label: "Selecciona un tipo",
        header: "Todos los tipos",
      },
      SCHOOLS: {
        label: "Selecciona una escuela",
        header: "Todas las escuelas",
      },
      SECTOR: {
        label: "Selecciona un sector",
        header: "Todos los sectores",
      },
      STAGE_TARGET: {
        label: "Selecciona un objetivo de escenario",
        header: "Todos los objetivos de escenario",
      },
      STUDY_TYPE: {
        label: "Selecciona una tipo de estudio",
        header: "Todos los tipos de estudio",
      },
      SUBJECT_AREA: {
        label: "Selecciona una asignatura",
        header: "Todas las asignaturas",
      },
      TOPIC: {
        label: "Selecciona un tema",
        header: "Todos los temas",
      },
      UNIT: {
        label: "Selecciona una unidad",
        header: "Todas las unidades",
      },
      YEAR: {
        label: "Selecciona un año",
        header: "Todos los años",
      },
      YEARS_EXPERIENCE: {
        label: "Selecciona años de experiencia",
        header: "Todos los años de experiencia",
      },
    },
  },

  en: {
    icons: {
      ariaLabel: {
        backString: "Back",
        bot: "Bot",
        closeAccordion: "Collapse the information",
        closeMenu: "Close menu",
        closeModal: "Close modal",
        closeSelector: "Close selector",
        closeSubmenu: "Close submenu",
        closeTranscription: "Close transcription",
        copied: "Copied",
        copy: "Copy",
        deleteFilter: "Delete filter",
        followUs: "Follow us",
        goToHome: "Go to home page",
        goToLinkedinProfile: "Go to Linkedin profile",
        goToNextBlogs: "Go to next blogs",
        goToNextPage: "Go to next page",
        goToNextPrograms: "Go to next programs",
        goToNextSlide: "Go to next slide",
        goToNextYear: "Go to next year",
        goToNextTabs: "Go to next tabs",
        goToPreviousBlogs: "Go to previous blogs",
        goToPreviousPage: "Go to previous page",
        goToPreviousPrograms: "Go to previous programs",
        goToPreviousSlide: "Go to previous slide",
        goToPreviousYear: "Go to previous year",
        goToPreviousTabs: "Go to previous tabs",
        lastMonth: "Last month",
        listView: "List view",
        mapView: "Map view",
        nextLevel: "Next level",
        nextMonth: "Next month",
        notShowAll: "Do not show all",
        notShowCalendar: "Do not show calendar",
        open: "Open",
        openAccordion: "Display more information",
        openMenu: "Open menu",
        openNewTab: "It will open in a new tab",
        openProgram: "Open program",
        openScholarship: "Open scholarship",
        openSelector: "Open selector",
        playVideo: "Play video",
        pauseVideo: "Pause video",
        represent: "Icon that represent",
        share: "Share",
        search: "Search",
        showAll: "Show all",
        showCalendar: "Show calendar",
        showLessVideos: "Show less videos",
        showMoreVideos: "Show more videos",
        watch: "Watch",
        removeProgramToCompare: "Remove program to compare",
      },
      arrows: {
        aDownload: "Download",
        dropDown: "Open",
        dropUp: "Close",
        externalLink: "External Link",
        higher: "Right arrow",
        less: "Left arrow",
      },
      components: {
        attach: "Attach",
        question: "Question",
        quote: "Quote",
      },
      initials: {
        backArrowSlim: "Download",
        backArrow: "Left",
        closeMenu: "Close menu",
        downArrow: "More information below",
        email: "Email",
        openMenu: "Open menu",
        openMenuFit: "Open menu",
        play: "Play",
        pause: "Pause",
      },
      socialMedia: {
        facebook: "Facebook",
        flickr: "Flickr",
        instagram: "Instagram",
        linkedin: "Linkedin",
        podcast: "Podcast",
        snapchat: "Snapchat",
        tikTok: "TikTok",
        whatsApp: "WhatsApp",
        x: "X",
        youtube: "Youtube",
      },
      general: {
        add: "More info",
        advancedSearch: "Advanced Search",
        award: "Award",
        calendar: "Calendar",
        case: "Case",
        close: "Close",
        comment: "Comment",
        device: "Device",
        document: "Document",
        info: "Info",
        list: "list",
        email: "Mail box",
        mapPoint: "Map point",
        microphone: "Microphone",
        notAdd: "Less info",
        phone: "Phone",
        programFavorite: "Favorite program",
        check: "Check",
        remove: "Less",
        search: "Search",
        shape: "Shape",
        shield: "Shield",
        student: "Student",
        time: "Time",
        user: "User",
        view: "View",
      },
      logos: {
        alumni: "Alumni Logo",
        architecture: "School of Architecture & Design Logo",
        architectureFifAnn:
          "School of Architecture & Design 50th Anniversary Logo",
        business: "Business School Logo",
        businessFifAnn: "Business School 50th Anniversary Logo",
        ccltm: "Center for Corporate Learning and Talent Management Logo",
        cgc: "Center for the Governance of Change Logo",
        gpc: "Global Policy Center Logo",
        corporateRelat: "Corporate Relations Logo",
        entrepreneurship: "Entrepreneurship",
        financialAID: "Financial AID Logo",
        foundation: "Foundation Logo",
        humanities: "School of Humanities Logo",
        humanitiesFifAnn: "School of Humanities 50th Anniversary Logo",
        ie: "IE Logo",
        ieNYC: "IE NYC Logo",
        law: "Law School Logo",
        lawFifAnn: "Law School 50th Anniversary Logo",
        leadership: "Center for Leadership & Innovation in me Logo",
        lifelong: "Lifelong Learning Logo",
        lifelongFifAnn: "Lifelong Learning 50th Anniversary Logo",
        spega: "School of Politics, Economics & Global Affairs Logo",
        spegaFifAnn:
          "School of Politics, Economics & Global Affairs 50th Anniversary Logo",
        sogp: "School of Global & Public affairs Logo",
        sogpFifAnn: "School of Global & Public affairs 50th Anniversary Logo",
        sst: "School of Science & Technology Logo",
        sstFifAnn: "School of Science & Technology 50th Anniversary Logo",
        talentCareer: "Talent & Careers Logo",
        uni: "University Logo",
        uniFifAnn: "University 50th Anniversary Logo",
      },
    },
    initial: {
      goToMain: "Skip to main content",
    },
    ui: {
      followUs: "Follow us",
      breadcrumb: { home: "Home" },
      loadMore: "Load more",
      showing: "showing",
      of: "of",
    },
    components: {
      addToCalendar: {
        add: "Add to calendar",
      },
      audiovisualCard: {
        aviable: "Available from ",
        listenPodcast: "Listen to podcast",
        watchVideo: "Watch video",
      },
      blogCard: { blogDetail: "Blog detail" },
      eventCard: {
        info: "More information",
        register: "Register",
      },
      featuredProgramCard: {
        discover: "Discover the program",
        apply: "Apply",
        filterOptions: "Filter options",
        quickFacts: {
          location: "location",
          format: "format",
          duration: "duration",
          language: "language",
          intake: "intake",
        },
      },
      insightsCard: { insights: "Detail of the insight" },
      newsCard: { newsDetail: "New's detail" },
      personCard: { profile: "'s profile" },
      programCategory: {
        programDetail: "Program detail",
      },
      projectCard: {
        authorProfessor: "Author (professor)",
        authorStudent: "Author (student)",
        subject: "Subject",
        date: "Date",
        program: "Program",
        tutor: "Tutor",
        projectLink: "Project link",
      },
      storyCard: {
        storyFrom: "'s story",
        hoverLink: "See the story",
      },
    },
    modules: {
      serverError: "Server Error",
      articlesDistributor: {
        readArticle: "Read the article",
        discoverArticles: "Discover more articles",
      },
      blogDistributor: {
        discoverBlogs: "Discover more blogs",
      },
      countdown: {
        days: "days",
        hours: "hours",
        minutes: "minutes",
      },
      feedbackForm: {
        name: { label: "Name*", error: "Enter a nmae" },
        surname: { label: "Surname*", error: "Enter a surname" },
        email: { label: "Email*", error: "Enter a valid email" },
        message: { label: "Message*", error: "Enter a message" },
        legalConditions: {
          prefix: "I accept the",
          linkText: "Legal Conditions",
          suffix: "",
          error: "You must accept the Legal Conditions",
        },
        send: {
          label: "Send",
          success: {
            prefix: "Thank you",
            suffix: "The information has been sent correctly",
          },
        },
      },
      generalInfo: {
        about: "About",
        more: "See more",
        filter: "Filtered General Info",
      },
      newsDistributor: {
        discoverNews: "Discover more news",
      },
      programDistributor: {
        apply: "Apply",
        discover: "Discover the program",
        discoverPrograms: "Discover more programs",
      },
      programQuickFacts: {
        duration: "Duration",
        language: "Language",
        location: "Location",
        intake: "Intake",
      },
      projectDistributor: {
        discoverProject: "Discover this project",
        discoverProjects: "Discover more projects",
      },
      publicationDistributor: {
        readPublication: "Read the complete publication",
        discoverPublications: "Discover more publications",
      },
      specializationDistributor: {
        apply: "Apply",
        viewDetail: "View detail",
        specialization: "Specialization",
      },
    },
    templates: {
      results: "Results",
      epicAwardList: {
        loading: "Loading...",
      },
      epicAwardDetail: {
        winner: "Winner of the EPIC Awards",
        nominated: "Nominated of the EPIC Awards",
      },
      eventList: {
        hybrid: "Hybrid Events",
        faceToFace: "Face To Face Events",
        online: "Online Events",
      },
      publicationDetail: {
        date: "Date",
        author: "Author(s)",
        organization: "Organization",
        ISBN: "ISBN",
        publication: "Publication",
        publicationType: "Publication Type",
        academicArea: "Academic Area(s)",
        citation: "Citation",
        share: "Share",
      },
      programDetail: {
        programTabDetail: "Program Details",
        downloadBrochure: "Download Brochure",
        apply: "Apply",
      },
      programComparator: {
        addToComparator: "Add to comparator",
        selectAtLeast: "Select at least two programs to compare",
        comparePrograms: "Compare the programs",
        otherProgram: "Other program",
        addedToComparator: "Added to comparator",
        changeThePrograms: "Change the programs to compare",
        seeTheDifferentFields: "See only the different fields",
        abstract: "Abstract",
        forPeopleWho: "For people who",
        toBecome: "To become",
        reasonsWhy: "Reasons why",
        beyondTheProgram: "Beyond the program",
        residentialPeriods: "Residential periods",
        findOutMore: "Find out more",
        apply: "Apply",
        discoverProgram: "Discover the program",
        fix: "Fix",
        fixed: "Fixed",
        remove: "Remove",
        noProgramSelected: "No program selected",
        addAnotherProgram: "Add another program",
      },
      programList: {
        discover: "Discover the program",
      },
      projectDetail: {
        program: "Program",
        authorTeacher: "Author (teacher)",
        authorStudent: "Author (student)",
        tutor: "Tutor",
        projectLink: "Project link",
        subject: "Subject",
        date: "Date",
      },
      projectList: {
        authorProfessor: "Professor",
        authorStudent: "Student",
      },
      storyDetail: {
        programStudied: "Program studied",
        aboutMe: "About me",
      },
      specializationDetail: {
        specialization: "Specialization",
        back: "Back",
      },
      teacherDetail: {
        name: "Name",
        lastName: "Last name",
        position: "Position",
        contact: "Contact",
        cv: "CV",
        linkedin: "Linkedin",
        personalWeb: "Personal web",
        nationality: "Nationality",
        orcid: "Orcid",
        scopus: "Scopus",
        externalLink: "External Link",
      },
    },
    introForm: {
      hubspot: "Hubspot Form",
    },
    forms: {
      firstName: { label: "First Name*", error: "Enter a name" },
      lastName: { label: "Last Name*", error: "Enter last name" },
      email: { label: "Email*", error: "Enter a valid email" },
      program: { label: "Program*", error: "Enter a program" },
      subject: { label: "Subject*", error: "Enter a subject" },
      intake: { label: "Intake*", error: "Enter a intake" },
      web: { label: "Web", error: "Enter a web" },
      phone: { label: "Phone*", error: "Enter a valid phone number" },
      company: { label: "Company Name*", error: "Enter a company" },
      image: {
        label: "Attach your logo",
        error: "Attach your logo of maximum 2MB",
      },
      city: { label: "City*", error: "Enter a city" },
      country: { label: "Country*", error: "Enter a country" },
      description: {
        label: "Short description (Max. 200 caracters)*",
        error: "Enter a description",
        errorLength: "Max. 200 characters",
      },
      allSectors: "All sectors",
      send: {
        label: "Send",
        success: {
          prefix: "Thank you",
          suffix: "The information has been sent correctly",
        },
      },
      noFileSelected: "No file selected",
      legalConditions: {
        prefix:
          "I consent to IE Foundation to process my personal data in order to manage my contribution in accordance with the stipulations of the",
        linkText: "Legal Conditions",
        suffix: "",
        error: "You must accept the Legal Conditions",
      },
      dataConditions: {
        prefix:
          "I consent, as a contributor, to my data being published in the Foundation's website.",
      },
      informationConditions: {
        prefix:
          "The IE Foundation organizes activities which may be of your interest. Please, mark the box if you are interested in being informed about this.",
      },
    },
    filters: {
      searchbar: "What are you looking for?",
      select: "Select",
      all: "Select all",
      filters: "Filters",
      improveSearch: "Advanced filters",
      clearFilters: "Clear filters",
      clearAdvancedFilters: "Clear advanced filters",
      applyFilters: "Apply filters",
      ACADEMIC_AREA: {
        label: "Select a academic area",
        header: "All academic areas",
      },
      AWARD_CATEGORY: {
        label: "Select a awards category",
        header: "All award categories",
      },
      CENTERS: {
        label: "Select a center & department",
        header: "All centers & departmentss",
      },
      CLASSIFICATION_TYPE: {
        label: "Select a classification type",
        header: "All classification types",
      },
      DURATION: {
        label: "Select a duration",
        header: "All durations",
      },
      EVENT_TYPE: {
        label: "Select an event type",
        header: "All event types",
      },
      FACULTY_CATEGORY: {
        label: "Select a faculty category",
        header: "All faculty categories",
      },
      FORMAT: {
        label: "Select a format",
        header: "All formats",
      },
      GENDER: {
        label: "Select a gender",
        header: "All genders",
      },
      INTAKE: {
        label: "Select a intake",
        header: "All intakes",
      },
      LANGUAGE: {
        label: "Select a language",
        header: "All languages",
      },
      LOCATION: {
        label: "Select a location",
        header: "All locations",
      },
      MODE_STUDY: {
        label: "Select a mode of study",
        header: "All modes of study",
      },
      PATHWAYS: {
        label: "Select a pathway",
        header: "All pathways",
      },
      PATHWAYS_EXECUTIVE: {
        label: "Select a pathway",
        header: "All pathways",
      },
      PROFILES: {
        label: "Select a profile",
        header: "All profiles",
      },
      PROGRAM_TYPE: {
        label: "Select a program type",
        header: "All program types",
      },
      PROGRAMS: {
        label: "Select a program",
        header: "All programs",
      },
      PODCAST_TYPE: {
        label: "Select a podcast type",
        header: "All podcasts types",
      },
      PUBLICATION_TYPE: {
        label: "Select a publication type",
        header: "All publication types",
      },
      REGION: {
        label: "Select a region",
        header: "All regions",
      },
      SCHOLARSHIP_TYPE: {
        label: "Select a type",
        header: "All types",
      },
      SCHOOLS: {
        label: "Select a school",
        header: "All schools",
      },
      SECTOR: {
        label: "Select a sector",
        header: "All sectors",
      },
      STAGE_TARGET: {
        label: "Select a stage target",
        header: "All stage targets",
      },
      STUDY_TYPE: {
        label: "Select a study type",
        header: "All study types",
      },
      SUBJECT_AREA: {
        label: "Select a subject area & value",
        header: "All subject areas & values",
      },
      TOPIC: {
        label: "Select a topic",
        header: "All topics",
      },
      UNIT: {
        label: "Select an unit",
        header: "All units",
      },
      YEAR: {
        label: "Select a year",
        header: "All years",
      },
      YEARS_EXPERIENCE: {
        label: "Select years of experience",
        header: "All years of experience",
      },
    },
  },
}
